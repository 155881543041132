// Global theme variable definition
@mixin global-theme {
  $ranked-background-color-light: #F2F2ED;
  $primary-color: #A01D26;
  $primary-color-light: mix($primary-color, white, 30);
  $accent-color: #7E8C8C;

  --primaryColor: #{$primary-color};
  --primaryColorLight: #{$primary-color-light};
  --primaryColorGray: #{mix($primary-color-light, gray, 70)};
  --secondaryColor: #{$accent-color};
  --secondaryColorDark: #{mix($accent-color, black, 40)};
  --secondaryColorLight: #{mix($accent-color, white, 80)};
  --thirdColor: #{$ranked-background-color-light};
  --gray: #979797;
  --shadowColor: #000000;
  --lightColor: #FFFFFF;
}
