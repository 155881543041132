@import 'variables';

button {
  appearance: button;
  color: unset;
  letter-spacing: unset;
  word-spacing: unset;
  text-transform: unset;
  text-indent: unset;
  text-shadow: unset;
  display: block;
  text-align: unset;
  align-items: unset;
  cursor: default;
  background-color: unset;
  box-sizing: unset;
  margin: 0;
  padding: 0;
  font: inherit;
  outline: none;
  border: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:disabled {
    filter: saturate(80%);
    opacity: 0.5;
  }
}

// global styling for shared ui components
ranked-input-enhancer input[rankedInput] {
  font: inherit;
  color: inherit;
  background-color: transparent !important;
  padding: 0.75em $ranked-space; // it looks better with a padding top and bottom based on the text size
  margin: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%;

  &::placeholder {
    color: themed('textColorLight');
  }

  &:-webkit-autofill {
    &, &:hover, &:focus {
      -webkit-text-fill-color: color;
      -webkit-box-shadow: 0 0 0 0 #fff inset;
      box-shadow: 0 0 0 0 #fff inset;
      transition: background-color 0s ease-in-out 5000s;
    }
  }
}
