@import 'global-theme';

.theme-global {
  @include global-theme();
}

/*
 * Match theme definitions
 */
.theme-red {
  $primary: #d0021b;
  $primaryLight: #ffb7b0;

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}

.theme-blue {
  $primary: #4b93e2;
  $primaryLight: #cae7ff;

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}

.theme-green {
  $primary: #3ab621;
  $primaryLight: #83e472;

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}

.complementary-theme-gray {
  --secondaryColor: #535353;
  --secondaryColorDark: #1a1a1a;
  --secondaryColorLight: #838383;
  --thirdColor: #fafafa;
  --gray: #979797;
}

.theme-yellow {
  $primary: #e9b53b; // 233 181 59
  $primaryLight: mix($primary, white, 30%);

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}

.theme-black {
  $primary: #000000;
  $primaryLight: mix($primary, white, 30%);

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}

.complementary-theme-darkblue {
  --secondaryColor: #002077;
  --thirdColor: #fafafa;
  --gray: #979797;
}

.theme-websitered {
  $primary: #A01D26;
  $primaryLight: #ffb7b0;

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}

.theme-websiteblack {
  $primary: #1F2329;
  $primaryLight: mix($primary, white, 30%);

  --primaryColor: #{$primary};
  --primaryColorLight: #{$primaryLight};
  --primaryColorGray: #{mix($primaryLight, gray, 70%)};
}


