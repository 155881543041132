@import 'keen-slider/keen-slider.min.css';

@import 'scss/themes.scss';
@import 'global-fonts';
@import 'shared-ui';

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  overscroll-behavior-y: none; // sadly this does not work in Safari atm :/
  background-color: $ranked-background-color-light;
}

a {
  color: $primary-color;
  text-decoration: none;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.hidden {
  display: none;
  visibility: hidden;
}

.overlay-container {
  position: absolute;

  &>* {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
}
