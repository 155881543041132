/*
 * Common variables
 */
 $ranked-font-size: 1rem;
 $ranked-font-size-subheading: 1.25rem;
 $ranked-font-size-heading: 1.5rem;

 $ranked-space: 1rem; // ~16px
 $ranked-space-tiny: $ranked-space / 4;
 $ranked-space-small: $ranked-space / 2;
 $ranked-space-bigger: $ranked-space * 1.5;
 $ranked-space-big: $ranked-space * 2;
 $ranked-space-huge: $ranked-space * 3;

 /*
  * Main theme
  */
 $ranked-background-color-light: #F2F2ED;
 $ranked-background-color-dark: #1F2329;
 $primary-color: #A01D26;
 $accent-color: #7E8C8C;
 $accent-color-light: #ABBEBE;
 $table-grass-color: #EAFBEB;
 $error-color: #DE4E4E;

 $ranked-animation-speed-fast: 0.3s;
 $ranked-animation-speed-normal: 1s;

 /*
  * Device related stuff
  */
$device-safe-area-top: env(safe-area-inset-top);
$device-safe-area-right: env(safe-area-inset-right);
$device-safe-area-bottom: env(safe-area-inset-bottom);
$device-safe-area-left: env(safe-area-inset-left);
